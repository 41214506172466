@import url("https://fonts.googleapis.com/css2?family=Bungee&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Brygada+1918&family=Bungee&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Marcellus+SC&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Marcellus+SC&family=Work+Sans:ital,wght@0,100;0,200;0,300;1,100;1,200&display=swap");
@font-face {
  font-family: "Coalition";

  src: url(./coalition/Coalition_v2..ttf) format("truetype");
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  color: #ffffff;
}

body::-webkit-scrollbar {
  width: 0.7em;
}

body::-webkit-scrollbar-track {
  background: #000;
}

body::-webkit-scrollbar-thumb {
  background-color: #d44c3b;
  border-radius: 10px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


:root {
  --primary-font: 'Work Sans';
}
/* :root {
  --themeColour: #2cb7c7;
  --themeColour: radial-gradient(
    200.65% 776.86% at -9.28% 116.91%,
    #904e95 15.44%,
    #3c1053 100%
  );
  --themeColour: #AA076B;
  --primary-font:"Karla", sans-serif;
} */
